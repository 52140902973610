<template>
  <div>
    <!-- Generating Report -->
    <b-modal
      v-model="downloading"
      title="Generating Report"
      @hidden="cancelGenerateReport"
      hide-footer
    >
      <b-card>
        <b-progress
          :value="percent"
          :max="100"
          show-value
          show-progress
          animated
        />
      </b-card>
    </b-modal>

    <!-- Save Report Builder Format Modal -->
    <b-modal
      id="saveReportFormatFormId"
      ref="saveReportFormatForm"
      ok-title="Save"
      @ok.prevent="saveReportFormat"
    >
      <b-form>
        <b-form-group label="Format Name">
          <b-form-input v-model="reportFormatName" />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Drag Object Options -->
    <b-modal
      id="columnOptionsId"
      ref="columnOptionsRef"
      :title="selectedCol.name"
      ok-title="Save"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent.stop="saveColumnOptions">
        <b-card
          v-if="selectedCol.filters && selectedCol.filters.length"
          title="Filters"
        >
          <b-form-group
            v-for="(item, index) in selectedCol.filters"
            :key="'filter_input' + index"
            :label="item.name"
          >
            <v-select
              v-if="item.type === 'select'"
              :placeholder="item.name"
              label="name"
              :options="filtersOptions[`${item.list_route}`]"
              :value="columnOptions.filtersValues[`${item.key}`]"
              @input="onFilterInput($event, item)"
            />

            <v-select
              v-else-if="item.type === 'multi-select'"
              :placeholder="item.name"
              label="name"
              :options="filtersOptions[`${item.list_route}`]"
              :value="columnOptions.filtersValues[`${item.key}`]"
              @input="onFilterInput($event, item)"
              multiple
              :close-on-select="false"
            />

            <treeselect
              allowSelectingDisabledDescendants
              v-else-if="item.type === 'tree-select'"
              :options="filtersOptions[`${item.list_route}`]"
              multiple
              :placeholder="item.name"
              :value="columnOptions.filtersValues[`${item.key}`]"
              @input="onFilterInput($event, item)"
            />

            <v-select
              v-else-if="item.type === 'boolean'"
              :placeholder="item.name"
              label="name"
              :options="[
                { name: 'Yes', value: true },
                { name: 'No', value: false },
              ]"
              :value="columnOptions.filtersValues[`${item.key}`]"
              @input="onFilterInput($event, item)"
            />

            <b-form-input
              v-else
              :placeholder="item.type"
              :value="columnOptions.filtersValues[`${item.key}`]"
              @input="onFilterInput($event, item)"
              :type="item.type"
            ></b-form-input>
          </b-form-group>
        </b-card>
        <b-card v-if="pColOptions && pColOptions.length" title="Pivoting">
          <b-form-group label="Columns">
            <v-select
              v-model="columnOptions.pivotCol"
              label="name"
              :options="pColOptions"
            >
              <template #selected-option="data">
                <span> {{ humanize(data.name) }}</span>
              </template>

              <template #option="data">
                <span> {{ humanize(data.name) }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group label="Rows">
            <v-select
              v-model="columnOptions.pivotRow"
              label="name"
              :options="pRowOptions"
            >
              <template #selected-option="data">
                <span> {{ humanize(data.name) }}</span>
              </template>

              <template #option="data">
                <span> {{ humanize(data.name) }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-card>
        <b-card
          v-if="filtersOptions.columns && filtersOptions.columns.length"
          title="Columns"
        >
          <v-select
            placeholder="Columns"
            label="name"
            :options="filtersOptions.columns"
            v-model="columnOptions.filtersValues[`columns`]"
            multiple
          >
            <template #selected-option="data">
              {{ humanize(data.name) }}
            </template>

            <template #option="data">
              {{ humanize(data.name) }}
            </template>
          </v-select>
        </b-card>
        <b-form-group>
          <b-button type="submit" variant="primary">Save</b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Preview Modal -->
    <b-modal
      ref="dataPreviewModalRef"
      title="Preview"
      size="xl"
      hide-footer
      @hidden="onPreviewModalHidden"
    >
      <b-card>
        <div class="table-container shadow-sm border">
          <table class="table preview-table">
            <tr class="bg-primary text-white">
              <th>Index</th>
              <th
                v-for="(h, h_i) in preview.headings"
                :key="'preview_heading_' + h_i"
              >
                {{ h }}
              </th>
            </tr>
            <tr v-for="(row, r_i) in preview.rows" :key="'preview_row_' + r_i">
              <td>{{ r_i + 1 }}</td>
              <td v-for="(d, d_i) in row" :key="'preview_data' + d_i">
                {{ d }}
              </td>
            </tr>
          </table>
        </div>

        <template #footer>
          <div>
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-end justify-content-end justify-content-sm-center"
              >
                <b-pagination
                  v-model="pagination.currentPage"
                  :total-rows="pagination.totalRows"
                  :per-page="pagination.rowsPerPage"
                  @change="onPaginationChange"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-card>
    </b-modal>

    <b-row class="d-flex align-items-end">
      <b-col cols="12" md="4">
        <b-form-group label="Report Type">
          <v-select
            placeholder="Type"
            label="name"
            :reduce="(i) => i.key"
            :options="objects"
            :value="objectKey"
            @input="onObjectSelect"
          />
        </b-form-group>
      </b-col>

      <span class="mx-1 mb-2"><b>OR</b></span>
      <b-col cols="12" md="4">
        <b-form-group label="Select from Saved Format">
          <v-select
            :disabled="!objectKey"
            label="name"
            placeholder="Format"
            :reduce="(i) => i.id"
            :options="reportFormats"
            :value="reportFormatId"
            @input="onReportFormatSelected"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="w-100">
          <b-form-input
            placeholder="Search For Label"
            @input="onSearchObjectSchema"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="row mt-5">
      <div class="col-12 col-md-12">
        <div class="d-flex">
          <h5><b>Labels</b></h5>
          <p class="text-xs" style="margin-left: 4px">
            (<small
              >Please Tap or Drag Labels to add them to
              <b>Output Labels.</b></small
            >)
          </p>
        </div>
        <draggable
          style="
            min-height: 75px;
            flex-wrap: wrap;
            background-color: #f9f9f9;
            padding: 10px;
          "
          v-bind="dragOptions"
          v-model="objectSchema"
          class="d-flex rounded-lg border justify-content-center"
          :list="objectSchema"
          group="people"
        >
          <div
            class="list-group-item text-nowrap rounded-lg shadow-sm border border-primary draggable-hover"
            style="margin: 2px"
            v-for="(element, index) in objectSchema"
            :key="element.name + 'from' + index"
            @click="() => handleLabelClick(element)"
          >
            <p class="text-primary">
              {{ element.name }}
              <b-badge v-if="element.showOptions" variant="light-warning"
                >F</b-badge
              >
            </p>
          </div>
        </draggable>
      </div>

      <hr class="m-1" />

      <div class="col-12 col-md-12">
        <div class="d-flex">
          <h5><b>Output Labels</b></h5>
          <p class="text-xs">
            <small class="d-flex" style="margin-left: 4px">
              (Drag out or Click
              <div>
                <b-badge
                  class="float-right h-100 d-flex align-items-center"
                  style="margin: 0 4px"
                  variant="light-danger"
                  @click="(event) => handleColumnClick(event, element)"
                >
                  <feather-icon icon="MinusIcon" class="text-body text-white" />
                </b-badge>
              </div>
              to remove labels from &nbsp;<b>Output Labels</b>.)
            </small>
          </p>
        </div>

        <draggable
          v-bind="dragOptions"
          style="
            min-height: 75px;
            overflow-x: auto;
            background-color: #f9f9f9;
            padding: 5px;
            padding-bottom: 10px;
          "
          v-model="columns"
          class="d-flex rounded-lg border"
          :list="columns"
          group="people"
        >
          <div
            class="list-group-item text-nowrap rounded-lg shadow-sm"
            style="margin-right: 2px"
            v-for="(element, index) in columns"
            :key="element.name + 'to' + index"
            @click="openColumnsOptionsModal(element, index)"
          >
            <div class="d-flex align-items-center justify-content-center h-100">
              <div
                class="d-flex align-items-center justify-content-center flex-column h-100"
              >
                <div style="margin-bottom: 5px">
                  <b>{{ element.name }}</b>
                </div>
                <div
                  class="d-flex flex-column w-100"
                  v-if="element.filtersText"
                >
                  <b-badge
                    v-for="(value, key) in getNotEmptyFilters(
                      element.filtersText
                    )"
                    variant="light-info"
                    style="margin-bottom: 5px"
                    class="border text-left"
                    :key="'filter_value' + value + key"
                  >
                    <div class="w-100 d-flex justify-content-between">
                      <div>
                        {{ getFilterName(key, element) }} :
                        {{ value }}
                      </div>
                      <feather-icon
                        icon="XCircleIcon"
                        class="text-danger ml-1"
                        @click="(e) => removeFilter(e, element, key)"
                      />
                    </div>
                  </b-badge>
                </div>
                <div class="d-flex flex-column w-100">
                  <b-badge
                    v-if="element.pivotCol"
                    variant="light-success"
                    style="margin-bottom: 5px"
                    class="w-100 text-left"
                    >Pivot Column:
                    {{ humanize(element.pivotCol.name) }}</b-badge
                  >
                  <b-badge
                    v-if="element.pivotRow"
                    variant="light-success"
                    style="margin-bottom: 5px"
                    class="w-100 text-left"
                    >Pivot Row: {{ humanize(element.pivotRow.name) }}</b-badge
                  >
                </div>
                <div v-if="element.showOptions && element.showOptions == true">
                  <b-badge class="float-right" variant="light-warning"
                    >apply filters</b-badge
                  >
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <b-badge
                  class="float-right ml-1 h-100 w-100 d-flex align-items-center"
                  variant="light-danger"
                  @click="(event) => handleColumnClick(event, element)"
                >
                  <feather-icon icon="MinusIcon" class="text-body text-white" />
                </b-badge>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <b-row
        v-if="columns && columns.length"
        slot="header"
        class="py-2 w-100"
        role="group"
        aria-label="Basic example"
      >
        <b-col class="d-flex justify-content-center w-100"
          ><b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="generateReport"
            >Download Excel</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-primary"
            @click="openSaveReportModal"
            >Save Report Format</b-button
          >
          <!-- <b-button
            size="sm"
            variant="outline-primary"
            @click="previewExcelData"
            >Preview</b-button
          >-->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BContainer,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BIcon,
  BIconPlus,
  BIconDash,
  BInputGroup,
  BFormInput,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeStrikethrough,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BPagination,
  VBTooltip,
  BProgress,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import * as ReportBuilderServices from "@/apiServices/ReportBuilderServices";
import vSelect from "vue-select";
import { downloadFromURL, getTreeLabelsById } from "@/utils/helpers";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { humanize } from "@/utils/helpers";
import Treeselect from "@riophae/vue-treeselect";
import { getMasterList } from "@/apiServices/MastersServices";
import { TokenService } from "@/apiServices/storageService";

export default {
  components: {
    BCard,
    BProgress,
    BFormSelect,
    BContainer,
    BFormSelectOption,
    draggable,
    BFormCheckbox,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BIcon,
    BIconPlus,
    BIconDash,
    BInputGroup,
    BFormInput,
    BIconTypeBold,
    BIconTypeItalic,
    BIconTypeStrikethrough,
    BBadge,
    vSelect,
    Treeselect,
    BButton,
    BForm,
    BFormGroup,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      // Generate Report
      downloading: false,
      controller: null,
      percent: 0,

      objectKey: "",
      objectSchema: [],
      objectSchemaMain: [],
      objects: [],
      columns: [],
      reportFormats: [],
      reportFormatId: "",
      reportFormatName: "",
      selectedCol: {},
      selectedColIndex: "",
      columnOptions: {
        filtersValues: {
          columns: [],
        },
        filtersText: {},
        pivotCol: "",
        pivotRow: "",
        columns: [],
        columnsKeys: [],
      },
      filtersOptions: {},
      pColOptions: [],
      pRowOptions: [],
      preview: {
        headings: [],
        rows: [],
      },
      pagination: {
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [25, 50, 100],
      },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    getNotEmptyFilters(filters) {
      const nonEmptyFilters = {};

      for (const key in filters) {
        if (
          filters[key] === "" ||
          filters[key] === null ||
          (Array.isArray(filters[key]) && filters[key].length === 0)
        ) {
          continue;
        }
        nonEmptyFilters[key] = filters[key];
      }

      return nonEmptyFilters;
    },
    getFilterName(key, element) {
      for (const f of element.filters) {
        if (f.key === key) {
          return f.name;
        }
      }
      return "";
    },
    removeFilter(event, element, key) {
      event.stopPropagation();
      if (element.filtersValues[key]) {
        delete element.filtersValues[key];
        delete element.filtersText[key];
      }
      this.$forceUpdate();
    },
    onFilterInput(i, item) {
      if (item.type === "multi-select") {
        this.columnOptions.filtersValues[`${item.key}`] = i.map((i) => i.id);
        this.columnOptions.filtersText[`${item.key}`] = i
          .map((i) => i.name)
          .join(",");
      } else if (item.type === "select") {
        this.columnOptions.filtersValues[`${item.key}`] = i.id;
        this.columnOptions.filtersText[`${item.key}`] = i.name;
      } else if (item.type === "tree-select") {
        this.columnOptions.filtersValues[`${item.key}`] = i;
        this.columnOptions.filtersText[`${item.key}`] = getTreeLabelsById(
          i,
          this.filtersOptions[`${item.list_route}`]
        );
      } else if (item.type === "boolean") {
        this.columnOptions.filtersValues[`${item.key}`] = i.value;
        this.columnOptions.filtersText[`${item.key}`] = i.name;
      } else {
        this.columnOptions.filtersValues[`${item.key}`] = i;
        this.columnOptions.filtersText[`${item.key}`] = i;
      }
    },
    getMultipleFilterName(filterOptions, keys) {
      try {
        if (!filterOptions) {
          return;
        }

        let nameArray = [];

        for (let key of keys) {
          let item;

          for (let filterOption of filterOptions) {
            let found = false;
            if (filterOption.key == key) {
              item = filterOption;
              found = true;
            }
            if (filterOption.children.length > 0) {
              for (let childFilterOption of filterOption.children) {
                if (childFilterOption.key == key) {
                  item = childFilterOption;
                  found = true;
                }
              }
            }
            if (found) {
              break;
            }
          }
          if (item) {
            nameArray.push(item.label);
          }
        }

        if (nameArray.length == 0) return "";
        return nameArray.toString().split(",").join(", ");
      } catch (error) {
        console.error(`Error in getMultipleFilterName `, error);
      }
    },
    handleLabelClick(element) {
      this.columns.push(element);
      this.objectSchema = this.objectSchema.filter(
        (object) => object.key !== element.key
      );
    },
    handleColumnClick(event, element) {
      event.stopPropagation();
      this.objectSchema.push(element);
      this.columns = this.columns.filter(
        (object) => object.key !== element.key
      );
    },
    humanize,
    onPaginationChange() {
      this.$nextTick(() => {
        this.previewExcelData();
      });
    },
    onPreviewModalHidden() {
      this.pagination = {
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [25, 50, 100],
      };
    },
    async previewExcelData() {
      try {
        const response = await ReportBuilderServices.GetPreviewReportExcel({
          key: this.objectKey,
          columns: this.columns,
          limit: this.pagination.rowsPerPage,
          offset:
            (this.pagination.currentPage - 1) * this.pagination.rowsPerPage,
          preview: true,
        });

        if (!response.data.status) {
          return;
        }

        const data = response.data.data;

        this.preview.headings = data.rows.shift();
        this.preview.rows = data.rows;

        this.pagination.totalRows = data.total;

        this.$refs["dataPreviewModalRef"].show();
      } catch (error) {
        console.error(`Error in download excel `, error);
      }
    },
    async openColumnsOptionsModal(col, index) {
      this.selectedCol = col;

      this.columnOptions = {};

      let showModal = false;

      if (col.filters && col.filters.length) {
        showModal = true;
        this.columnOptions = {
          filtersValues: col.filtersValues || {},
          filtersText: col.filtersText || {},
        };

        const filtersOptions = this.filtersOptions;
        this.filtersOptions = [];
        for (const i of col.filters) {
          if (["select", "tree-select", "multi-select"].includes(i.type)) {
            if (
              !filtersOptions[`${i.list_route}`] ||
              !filtersOptions[`${i.list_route}`].length
            ) {
              const data = await getMasterList({ list_route: i.list_route });
              filtersOptions[`${i.list_route}`] = data.data.data;
            }
          }
        }
        this.filtersOptions = filtersOptions;
      }

      if (col.columns) {
        showModal = true;
        this.pColOptions = col.columns.filter((i) => i.p_col);
        this.pRowOptions = col.columns.filter((i) => i.p_row);
        this.columnOptions = {
          ...this.columnOptions,
          pivotCol: col.pivotCol,
          pivotRow: col.pivotRow,
        };

        this.filtersOptions.columns = [];
        if (col.columnOption) {
          this.filtersOptions.columns = col.columns.map((i) => ({
            key: i.key,
            name: i.name,
          }));
        }
      }

      if (showModal) {
        this.selectedColIndex = index;
        this.$refs["columnOptionsRef"].show();
      }
    },
    saveColumnOptions() {
      if (this.columnOptions.pivotCol) {
        if (!this.columnOptions.pivotRow) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Select Row for pivoting",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }
        if (
          JSON.stringify(this.columnOptions.pivotCol) ===
          JSON.stringify(this.columnOptions.pivotRow)
        ) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Column and Row cannot be same",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }
      }

      this.columns[this.selectedColIndex] = {
        ...this.columns[this.selectedColIndex],
        pivotCol: this.columnOptions.pivotCol,
        pivotRow: this.columnOptions.pivotRow,
        filtersValues: this.columnOptions.filtersValues,
        filtersText: this.columnOptions.filtersText,
      };

      this.selectedCol = "";
      this.selectedColIndex = 0;
      this.$refs["columnOptionsRef"].hide();
    },
    onSearchObjectSchema(i) {
      if (i) {
        this.objectSchema = this.objectSchemaMain.filter((d) => {
          const name = d.name;
          if (name && typeof name === "string") {
            return name.toLowerCase().includes(i.toLowerCase());
          }
        });
      } else {
        this.objectSchema = this.objectSchemaMain;
      }
    },
    openSaveReportModal() {
      this.$refs["saveReportFormatForm"].show();
    },
    async onReportFormatSelected(id) {
      this.reportFormatId = id;
      if (!id) {
        return;
      }
      try {
        const res = await ReportBuilderServices.GetSingleFormats(id);
        if (res.data.data) {
          this.reportFormatId = id;
          this.reportFormatName = res.data.data.name;
          this.columns = JSON.parse(res.data.data.format_text);
        }
      } catch (error) {
        console.error(`Error in onReportFormatSelected `, error);
      }
    },
    async saveReportFormat() {
      try {
        const res = await ReportBuilderServices.SaveFormat({
          id: this.reportFormatId,
          type: "EXCEL",
          name: this.reportFormatName,
          key: this.objectKey,
          format_text: JSON.stringify(this.columns),
        });
        if (res.data.status) {
          this.reportFormatId = res.data.data;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          this.getReportFormats(this.objectKey);
        }
      } catch (error) {
        console.error(`Error in saveReportFormat `, error);
      }
      this.$refs["saveReportFormatForm"].hide();
    },
    async cancelGenerateReport() {
      this.downloading = false;
      this.percent = 0;
      if (this.controller) {
        alert("Report Generate Cancelled");
        this.controller.abort();
      }
      this.controller = null;
    },
    async generateReportComplete(file_name) {
      try {
        this.downloading = false;
        this.percent = 0;
        this.controller = null;
        const reportName = this.reportFormatName || "report";

        const token = TokenService.getToken();
        const url = `${process.env.VUE_APP_BASEURL}/reportsBuilder/excel-download/${file_name}?authorization=${token}&report_name=${reportName}`;

        downloadFromURL(url, reportName, "xlsx");
      } catch (error) {
        console.error(`Error in downloading excel `, error);
        alert("Error in downloading excel");
      }
    },
    async generateReport() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in generating report");
          return;
        }

        this.downloading = true;

        this.controller = new AbortController();
        const { signal } = this.controller;

        const response = await fetch(
          `${process.env.VUE_APP_BASEURL}/reportsBuilder/excel?authorization=${token}`,
          {
            headers: {
              "Transfer-Encoding": "chunked",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              report_name: this.reportFormatName || "report",
              key: this.objectKey,
              columns: this.columns,
            }),
            method: "POST",
            signal,
          }
        );

        const reader = response.body.getReader();

        while (true) {
          const data = await reader.read();
          const jsonStr = String.fromCharCode.apply(null, data.value);
          const json = JSON.parse(jsonStr);

          if (typeof json.status === "boolean" && !json.status) {
            alert(json.message);
            this.cancelGenerateReport();
            return;
          }

          if (json.error) {
            alert(json.message);
            this.cancelGenerateReport();
            return;
          }

          if (json.finished) {
            this.percent = 100;
            this.generateReportComplete(json.file_name);
            return;
          }

          if (json.total && json.index) {
            this.percent = parseFloat(
              ((json.index * 100) / json.total).toFixed(1)
            );
          }
          if (json.data) {
            this.data = json.data;
          }
        }
      } catch (error) {
        console.error("Error in generating report ", error);
        this.cancelGenerateReport();
      }
    },
    onObjectSelect(input) {
      this.clearAll();
      this.getObjectSchema(input);
      this.objectKey = input;
      this.getReportFormats(input);
    },
    async getObjectSchema(key) {
      try {
        const res = await ReportBuilderServices.GetObjectsSchema(key);
        let objectSchema = res.data.data;
        if (this.columns && this.columns.length) {
          objectSchema = objectSchema.filter((i) => {
            for (const j of this.columns) {
              if (j.key === i.key) {
                return false;
              }
            }
            return true;
          });
        }
        this.objectSchema = objectSchema;
        this.objectSchemaMain = objectSchema;
      } catch (error) {
        console.error(`Error in getObjectSchema`, error);
      }
    },
    async getObjects() {
      try {
        const res = await ReportBuilderServices.GetObjects();
        this.objects = res.data.data;
      } catch (error) {
        console.error(`Error in getObjects `, error);
      }
    },
    async getReportFormats(objectKey) {
      try {
        const res = await ReportBuilderServices.GetAllFormats({
          type: "EXCEL",
          key: objectKey,
        });
        if (res.data.status) {
          this.reportFormats = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getReportFormats`, error);
      }
    },
    clearAll() {
      this.reportFormatId = "";
      this.reportFormatName = "";
      this.columns = [];
      this.objectSchema = [];
      this.objectSchemaMain = [];
    },
  },
  beforeMount() {
    this.getObjects();
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.preview-table {
  overflow-x: scroll;
}

// display: block;
//   max-width: -moz-fit-content;
//   max-width: fit-content;
//   margin: 0 auto;
//   overflow-x: auto;
// white-space: nowrap;

.table-container {
  border-radius: 1rem;
  border-width: 3px;
  overflow-x: scroll;
  overflow-y: scroll;
  width: auto;
  height: auto;
  // border: 1px solid black; /* Add border to the container */
}

.draggable-hover:hover {
  background-color: #f7f7f7;
}

.preview-table {
  border-collapse: separate;
  /* Separate borders to prevent repetition */
  border-spacing: 0;
  /* Set spacing between table cells to 0 */
}

.preview-table th,
.preview-table td {
  border: 0.8px solid #efefef;
}

/* Style table cells */
</style>
